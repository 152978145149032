import React from "react";

import "@fontsource/overpass";
import "@fontsource/hind";

import Footer from "../components/footer/Footer";
import Navigation from "../components/navbar/Navigation";

const Style = ({ children }) => {
    return (
        <>
            <Navigation
                top={"0"}
                width={"100%"}
                position={"sticky"}
                zIndex={"10"}
            />
            {children}
            <Footer mt={"24"} />
        </>
    );
};

export default Style;
