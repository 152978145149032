import React from "react";

import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Divider,
    Spacer,
    Select,
    Flex,
    FormLabel,
    FormControl,
} from "@chakra-ui/react";
import {
    AiFillFacebook,
    AiFillLinkedin,
    AiFillInstagram,
} from "react-icons/ai";
import {
    useIntl,
    changeLocale,
    IntlContextConsumer,
} from "gatsby-plugin-react-intl";

import SocialButton from "../SocialButton";
import ListHeader from "../ListHeader";
import Logo from "../Logo";
import { LANGUAGES_SUPPORTED_NAMES, SIZES } from "../../constants";

/**
 * @description Renders our footer
 * @returns {JSX.Element}
 */
const Footer = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Box as="footer" bg={"footer"} color={"white"} {...rest}>
            <Container as={Stack} maxW={SIZES.containerMaxWidth} p={10}>
                <SimpleGrid
                    templateColumns={{
                        sm: "1fr",
                        md: "2fr 2fr 2fr 1fr",
                    }}
                    spacing={8}
                >
                    <Stack spacing={6}>
                        <Box>
                            <Logo height={"8"} width={"60"} />
                        </Box>
                        <Stack direction={"row"} spacing={"0"}>
                            <SocialButton
                                label={"Facebook"}
                                href={"https://www.facebook.com/cloudify.biz"}
                            >
                                <AiFillFacebook size={"20"} />
                            </SocialButton>
                            <SocialButton
                                label={"Instagram"}
                                href={"https://www.instagram.com/cloudify.biz/"}
                            >
                                <AiFillInstagram size={"20"} />
                            </SocialButton>
                            <SocialButton
                                label={"Linkedin"}
                                href={
                                    "https://www.linkedin.com/company/cloudify.biz/"
                                }
                            >
                                <AiFillLinkedin size={"20"} />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>
                            {intl.formatMessage({
                                defaultMessage: "Company",
                            })}
                        </ListHeader>
                        <Link href={"#features"}>
                            {intl.formatMessage({
                                defaultMessage:
                                    "Why Business process Automation",
                            })}
                        </Link>
                        <Link href={"https://www.cloudify.biz/about-us"}>
                            {intl.formatMessage({
                                defaultMessage: "About Us",
                            })}
                        </Link>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>
                            {intl.formatMessage({
                                defaultMessage: "Product",
                            })}
                        </ListHeader>
                        <Link href={"https://www.cloudify.biz/integration"}>
                            {intl.formatMessage({
                                defaultMessage:
                                    "Custom integrations with Cloudify",
                            })}
                        </Link>
                        <Link href={"https://marketplace.cloudify.biz/demo"}>
                            {intl.formatMessage({
                                defaultMessage:
                                    "Book a demo for workflow automation",
                            })}
                        </Link>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>
                            {intl.formatMessage({
                                defaultMessage: "Contact",
                            })}
                        </ListHeader>

                        <Link href={"https://www.cloudify.biz/contact"}>
                            {intl.formatMessage({
                                defaultMessage: "Contact us",
                            })}
                        </Link>
                    </Stack>
                </SimpleGrid>
                <Divider pt="20" />
                <Container
                    as={Stack}
                    maxW={"8xl"}
                    py={4}
                    direction={{ base: "column", md: "row" }}
                    spacing={10}
                    justify={{ md: "space-between" }}
                    align={{ md: "center" }}
                >
                    <Link href={"/"}>
                        {intl.formatMessage({
                            defaultMessage: "Calculator",
                        })}
                    </Link>
                    <Link
                        href={"https://www.cloudify.biz/terms-and-conditions"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Terms of Services",
                        })}
                    </Link>
                    <Link href={"https://www.cloudify.biz/privacy-policy"}>
                        {intl.formatMessage({
                            defaultMessage: "Privacy Policy",
                        })}
                    </Link>
                    <Link href={"#"}>
                        {intl.formatMessage({
                            defaultMessage: "Legal Notice",
                        })}
                    </Link>
                    <Link href={"/sitemap/sitemap-index.xml"}>
                        {intl.formatMessage({
                            defaultMessage: "Sitemap",
                        })}
                    </Link>
                    <Spacer />

                    <FormControl
                        as={Flex}
                        alignItems={"center"}
                        width={{ base: "70", md: "80" }}
                        justify={{ base: "start", md: "end" }}
                    >
                        <FormLabel width={"fit-content"}>
                            {intl.formatMessage({
                                defaultMessage: "Language & Region:",
                            })}
                        </FormLabel>
                        <IntlContextConsumer>
                            {({ languages, language: currentLocale }) => (
                                <Select
                                    onChange={(event) =>
                                        changeLocale(event.target.value)
                                    }
                                    defaultValue={currentLocale}
                                    width={"30"}
                                >
                                    {languages.map((language) => (
                                        <option
                                            key={language}
                                            value={language}
                                            style={{ color: "black" }}
                                        >
                                            {
                                                LANGUAGES_SUPPORTED_NAMES[
                                                    language
                                                ]
                                            }
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </IntlContextConsumer>
                    </FormControl>
                </Container>
            </Container>
        </Box>
    );
};

export default Footer;
