import React from "react";

import {
    Box,
    Flex,
    Text,
    Stack,
    Icon,
    Link,
    useColorModeValue,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

/**
 * @description Renders sub nav item for Desktop
 * @param {object} props
 * @param {string} props.label Label for nav item
 * @param {string} props.href Link for nav item
 * @param {string} [props.subLabel] Sub label for nav item if any
 * @returns {JSX.Element}
 */
const DesktopSubNav = ({ label, href, subLabel, ...rest }) => {
    const backgroundHoverColor = useColorModeValue("blue.50", "blue.100");
    const groupHoperColor = "blue.400";

    return (
        <Link
            href={href}
            role={"group"}
            display={"block"}
            p={2}
            rounded={"md"}
            _hover={{ bg: backgroundHoverColor }}
            {...rest}
        >
            <Stack direction={"row"} align={"center"}>
                <Box>
                    <Text
                        transition={"all .3s ease"}
                        _groupHover={{ color: groupHoperColor }}
                        fontWeight={500}
                    >
                        {label}
                    </Text>
                    <Text fontSize={"sm"}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                        opacity: "100%",
                        transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                >
                    <Icon
                        color={groupHoperColor}
                        w={5}
                        h={5}
                        as={ChevronRightIcon}
                    />
                </Flex>
            </Stack>
        </Link>
    );
};

export default DesktopSubNav;
