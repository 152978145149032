import React, { useEffect, useRef } from "react";

import {
    Box,
    Flex,
    IconButton,
    Collapse,
    useDisclosure,
    Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useIntl } from "gatsby-plugin-react-intl";

import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { SIZES } from "../../constants";
import Logo from "../Logo";

/**
 * @description Renders our navigation bar.
 * @returns {JSX.Element}
 */
const Navigation = ({ ...rest }) => {
    const { isOpen, onToggle } = useDisclosure();
    const navRef = useRef();
    const intl = useIntl();
    const NAV_ITEMS = navItems(intl);

    useEffect(() => {
        const onScroll = (event) => {
            if (window.pageYOffset > 0) {
                navRef.current.style.boxShadow =
                    "0px 2px 5px 0px rgba(0,0,0,0.75)";
            } else {
                navRef.current.style.boxShadow = "";
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);
    return (
        <Box
            bg={"header"}
            color={"white"}
            transition="box-shadow 0.2s ease-in-out;"
            ref={navRef}
            width="100%"
            {...rest}
        >
            <Flex
                minH={"60px"}
                py={3}
                px={{ base: "4", md: "10" }}
                align={"center"}
                maxW={SIZES.containerMaxWidth}
                margin={"0 auto"}
            >
                <Flex ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={"ghost"}
                        aria-label={"Toggle Navigation"}
                    />
                </Flex>
                <Flex flex={{ base: 1 }}>
                    <Logo height={"8"} width={"60"} />
                    <Spacer />
                    <Flex
                        display={{ base: "none", md: "flex" }}
                        justify={"right"}
                        mx={10}
                        width={"100%"}
                    >
                        <DesktopNav navItems={NAV_ITEMS} />
                    </Flex>
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav navItems={NAV_ITEMS} />
            </Collapse>
        </Box>
    );
};

const navItems = (intl) => [
    {
        label: intl.formatMessage({
            defaultMessage: "Contact Us",
        }),
        href: "https://www.cloudify.biz/contact",
    },
    {
        label: intl.formatMessage({
            defaultMessage: "Our story",
        }),
        href: "https://www.cloudify.biz",
    },
];

export default Navigation;
