import React from "react";

import { chakra, VisuallyHidden } from "@chakra-ui/react";

/**
 * @description Renders a social button.
 * @param {object} props
 * @param {JSX.Element} props.children Child to render inside social button
 * @param {string} props.label Label for the social button
 * @param {string} props.href Link for the button
 * @returns {JSX.Element}
 */
const SocialButton = ({ children, label, href, ...rest }) => {
    const backgroundHoverColor = "blackAlpha.200";

    return (
        <chakra.button
            rounded={"sm"}
            w={8}
            h={8}
            cursor={"pointer"}
            as={"a"}
            href={href}
            display={"inline-flex"}
            alignItems={"center"}
            justifyContent={"center"}
            transition={"background 0.3s ease"}
            _hover={{
                bg: backgroundHoverColor,
            }}
            rel={"noopener"}
            target="_blank"
            {...rest}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default SocialButton;
