import { LANGUAGES_SUPPORTED as LS } from "./languages";

export const SIZES = {
    containerMaxWidth: "7xl",
    featureContainerMaxWidth: "4xl",
};

export const LANGUAGES_SUPPORTED = LS;

export const LANGUAGES_SUPPORTED_NAMES = Object.freeze({
    [LANGUAGES_SUPPORTED.EN]: "English",
    [LANGUAGES_SUPPORTED.DA]: "Danish",
});

export const STORAGE_KEYS = Object.freeze({
    contactInfo: "contact-info",
});

export const HUBSPOT = Object.freeze({
    portalID: "5236568",
    contactFormGUID: "c820f546-9a7f-45fb-9de2-bb423ab54dd9",
    contactFormUrl: `https://api.hsforms.com/submissions/v3/integration/submit/5236568/c820f546-9a7f-45fb-9de2-bb423ab54dd9`,
});
