import { extendTheme } from "@chakra-ui/react";

const theme = {
    fonts: {
        heading: "Overpass",
        body: "Hind",
    },
    colors: {
        blue: {
            50: "#ECF1F9",
            100: "#C9D6ED",
            200: "#A7BCE2",
            300: "#84A2D7",
            400: "#6188CC",
            500: "#3F6EC0",
            600: "#32589A",
            700: "#264273",
            800: "#192C4D",
            900: "#0D1626",
        },
        header: "#315696",
        footer: "#353854",
        overviewBg: "#02024B",
        benefitCard: "#EBF5FF",
    },
    config: { initialColorMode: "light", useSystemColorMode: false },
};

export default extendTheme(theme);
