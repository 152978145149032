import React from "react";

import { Stack } from "@chakra-ui/react";

import MobileNavItem from "./MobileNavItem";

/**
 * @description Renders mobile navigation
 * @param {object} props
 * @param {Array.<{label:string, href: string, children: {label: string, subLabel:string, href: string}}>} props.navItems List of navigation items
 * @returns
 */
const MobileNav = ({ navItems, ...rest }) => {
    return (
        <Stack bg={"header"} p={4} display={{ md: "none" }} {...rest}>
            {navItems.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

export default MobileNav;
