import React from "react";

// eslint-disable-next-line no-unused-vars
import { Icon, ChakraProps } from "@chakra-ui/react";

/**
 * @description Renders our logo
 * @param {ChakraProps} props
 * @returns {JSX.Element}
 */
const Logo = ({ ...rest }) => {
    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width={"96"}
            height={"8"}
            viewBox="0 0 284 38.25"
            {...rest}
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e9f5ff"></stop>
                    <stop offset="1" stopColor="#5ea4ff"></stop>
                </linearGradient>
            </defs>
            <g transform="translate(-275 -47)">
                <text
                    fill="#fff"
                    fontFamily="Overpass-Bold, Overpass"
                    fontSize="20"
                    fontWeight="700"
                    transform="translate(317 74)"
                >
                    <tspan x="0" y="0">
                        Automation RoI Calculator
                    </tspan>
                </text>
                <g data-name="calculator" transform="translate(196.619 47)">
                    <g data-name="Group 19740" transform="translate(78.381)">
                        <path
                            fill="url(#linear-gradient)"
                            d="M102.679 0H80.622a2.241 2.241 0 00-2.241 2.241v33.768a2.241 2.241 0 002.241 2.241h22.056a2.241 2.241 0 002.241-2.241V2.241A2.241 2.241 0 00102.679 0zM82.192 5.227a.374.374 0 01.374-.374h18.169a.374.374 0 01.374.374v4.031a.374.374 0 01-.374.374H82.566a.374.374 0 01-.374-.374zm4.093 28.6a.374.374 0 01-.374.374h-3.345a.374.374 0 01-.374-.374v-3.343a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm0-7.279a.374.374 0 01-.374.374h-3.345a.374.374 0 01-.374-.374V23.2a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm0-7.279a.374.374 0 01-.374.374h-3.345a.374.374 0 01-.374-.374v-3.343a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm7.415 14.56a.374.374 0 01-.374.374h-3.348a.374.374 0 01-.374-.374v-3.345a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm0-7.279a.374.374 0 01-.374.374h-3.348a.374.374 0 01-.374-.374V23.2a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm0-7.279a.374.374 0 01-.374.374h-3.348a.374.374 0 01-.374-.374v-3.345a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm7.412 14.558a.374.374 0 01-.374.374h-3.349a.374.374 0 01-.374-.374v-3.345a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm0-7.279a.374.374 0 01-.374.374h-3.349a.374.374 0 01-.374-.374V23.2a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374zm0-7.279a.374.374 0 01-.374.374h-3.349a.374.374 0 01-.374-.374v-3.345a.374.374 0 01.374-.374h3.346a.374.374 0 01.374.374z"
                            data-name="Path 27096"
                            transform="translate(-78.381)"
                        ></path>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default Logo;
