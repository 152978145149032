import React from "react";

import {
    Box,
    Stack,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@chakra-ui/react";

import DesktopSubNav from "./DesktopSubNav";

/**
 * @description Renders navigation for Desktop
 * @param {object} props
 * @param {Array.<{label:string, href: string, children: {label: string, subLabel:string, href: string}}>} props.navItems List of navigation items
 * @returns {JSX.Element}
 */
const DesktopNav = ({ navItems, ...rest }) => {
    const linkColor = "white";
    const linkHoverColor = "gray.200";
    const popoverContentBgColor = "blue.400";

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={4} {...rest}>
            {navItems.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={"hover"} placement={"bottom-start"}>
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.href ?? "#"}
                                fontSize={"sm"}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: "none",
                                    color: linkHoverColor,
                                }}
                            >
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={"xl"}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={"xl"}
                                minW={"sm"}
                            >
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav
                                            key={child.label}
                                            {...child}
                                        />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

export default DesktopNav;
