import React from "react";

import { Heading } from "@chakra-ui/react";

/**
 * @description Renders header for any list.
 * @param {object} props
 * @param {JSX.Element} props.children Children to render inside Header
 * @returns {JSX.Element}
 */
const ListHeader = ({ children, ...rest }) => {
    return (
        <Heading fontWeight={"bold"} fontSize={"lg"} mb={4} {...rest}>
            {children}
        </Heading>
    );
};
export default ListHeader;
